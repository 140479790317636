<template>
<div v-if="isLoadingPage">
    <h1>Loading ...</h1>
</div>
<div v-else class="grid-page-wrapper">
    <h1 class="display-5">Product dashboard: {{ product.reference }}</h1>
    <hr>
    <div class="row mb-2">
        <div class="col-md-3">
            <div class="row g-0 border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
                <div class="col p-4 d-flex flex-column position-static bg-card">
                    <!-- <strong class="d-inline-block mb-2 text-success">Design</strong> -->
                    <h5 class="mb-2 text-primary">Total time (HH:mm:ss)</h5>
                    <!-- <div class="mb-1 text-muted">Nov 12</div> -->
                    <strong class="card-text mb-auto">{{ metrics.totalTime }}</strong>
                </div>
            </div>
        </div>
        <div class="col-md-3">
            <div class="row g-0 border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
                <div class="col p-4 d-flex flex-column position-static bg-card">
                    <h5 class="mb-2 text-primary">Products manufactured</h5>
                    <!-- <div class="mb-1 text-muted">Nov 11</div> -->
                    <strong class="mb-auto">{{ metrics.sumProducts }} items</strong>
                </div>
            </div>
        </div>
        <div class="col-md-3">
            <div class="row g-0 border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
            <div class="col p-4 d-flex flex-column position-static bg-card">
                <h5 class="mb-2 text-primary">Mean products by work order</h5>
                <!-- <div class="mb-1 text-muted">Nov 11</div> -->
                <strong class="mb-auto">{{ metrics.meanProductsOrder }}</strong>
            </div>
            </div>
        </div>
        <div class="col-md-3">
            <div class="row g-0 border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
            <div class="col p-4 d-flex flex-column position-static bg-card">
                <h5 class="mb-2 text-primary">Number of work orders</h5>
                <!-- <div class="mb-1 text-muted">Nov 11</div> -->
                <strong class="mb-auto">{{ metrics.totalOrders }}</strong>
            </div>
            </div>
        </div>
    </div>
    <div class="row mb-2">
        <div class="col-12">
            <button class="btn btn-secondary dropdown-toggle"                     
                    id="btn-dropdown-export-table"
                    data-bs-toggle="dropdown">
                Export
            </button>
            <ul class="dropdown-menu" aria-labelledby="btn-dropdown-export-table">
                <li><button class="dropdown-item" @click="onBtnExport()"><i class="fa-solid fa-file-csv"></i> CSV</button></li>
            </ul>
        </div>
    </div>
    <div class="grid-wrapper">
        <ag-grid-vue
            class="backoffice-grid ag-theme-alpine"
            :columnDefs="columnDefs"
            :defaultColDef="defaultColDef"
            :paginationAutoPageSize="true"
            :pagination="true"
            :frameworkComponents="frameworkComponents"
            @grid-ready="onGridReady"
            @model-updated="onGridModelUpdated"
            :rowData="rowData">
        </ag-grid-vue>
    </div>

    <div class="row mt-2">
        <div class="col">
            <router-link :to="{ name: 'backoffice-dashboard-products' }" class="btn btn-outline-secondary float-end">
                <i class="bi bi-arrow-return-left"></i> Go back
            </router-link>
        </div>
    </div>
</div>
</template>

<script>
import "ag-grid-community/dist/styles/ag-grid.css"
import "ag-grid-community/dist/styles/ag-theme-alpine.css"
import { AgGridVue } from "ag-grid-vue3"
import { onMounted, reactive, ref } from 'vue'

import { dateRenderer } from '../../shared/helpers/GridRenderers'
import { seconds2TimeString } from '../../shared/helpers/dateTimeHelpers'
import { useGrid } from '../../shared/composables/useGrid'
import { dateComparator } from '../../shared/helpers/GridComparators'

import { getProductInfoDetails } from '../helpers/endpoints';
import CellGridOrderStatus from '../components/CellGridOrderStatus'


const timeSpendRenderer = (timeSpend) => {
    if (timeSpend.value)
        return seconds2TimeString(timeSpend.value)
    else
        return '-'
}

export default {
    components: { AgGridVue },
    props: {
        idProduct: {
            required: true,
            type: Number
        }
    },
    setup(props) {
        const { 
            defaultColDef, 
            rowData,
            onGridReady,
            exportAsCSV
        } = useGrid(() => { })

        const columnDefs = reactive([
                { headerName: "Code", field: "code", filter: 'agTextColumnFilter' },
                { headerName: "Product", field: "product", filter: 'agTextColumnFilter' },
                { headerName: "Quantity", field: "quantity", filter: 'agTextColumnFilter' },
                { 
                    headerName: "Start date", 
                    field: "start_date",
                    filter: 'agDateColumnFilter',
                    cellRenderer: dateRenderer ,
                    filterParams: {
                        // provide comparator function
                        comparator: dateComparator
                    } 
                },
                { 
                    headerName: "Status", 
                    field: "status",
                    cellRenderer: 'statusRenderer'
                },
                {
                    headerName: 'Registry time (HH:mm:ss)',
                    field: 'time',
                    cellRenderer: timeSpendRenderer
                },
                {
                    headerName: 'time/item (HH:mm:ss)',
                    field: 'time',
                    valueGetter: (params) => {
                        return seconds2TimeString(params.data['time'] / params.data['quantity'])
                     },
                }
            ])

        const frameworkComponents = reactive({
            statusRenderer: CellGridOrderStatus
        })

        const product = ref()
        const isLoadingPage = ref(true)

        const metrics = reactive({
            'totalTime': 0,
            'sumProducts': 0,
            'meanProductsOrder': 0,
            'totalOrders': 0
        })

        onMounted(() => {
            getProductInfoDetails(props.idProduct).then((response) => {
                console.log(response)
                rowData.value = response.orders
                product.value = response.product
                console.log( product.value )
                isLoadingPage.value = false
            })
        })
        

        return {
            columnDefs,
            defaultColDef,
            product,
            isLoadingPage,
            rowData,
            frameworkComponents,

            onGridReady,
            onBtnExport: () => exportAsCSV(),

            metrics,

            onGridModelUpdated: (params) => {
                console.log(params);
                const {api, keepRenderedRows} = params
                if (keepRenderedRows) {
                    console.log("Model Updated -> same rows");
                } else {
                    console.log("Model Update -> new rows");
                }

                // const rowModel = api.getRenderedNodes()
                // console.log(rowModel);

                const rowModel = api.getModel()
                metrics['totalTime'] = 0
                metrics['sumProducts'] = 0
                metrics['meanProductsOrder'] = 0
                metrics['totalOrders'] = 0
                rowModel.forEachNodeAfterFilter((rowNode) => {
                    metrics['totalTime'] += rowNode.data.time
                    metrics['sumProducts'] += rowNode.data.quantity
                    metrics['totalOrders'] ++
                });

                metrics['meanProductsOrder'] = metrics['sumProducts'] / metrics['totalOrders']
                metrics['totalTime'] = seconds2TimeString(metrics['totalTime'])
            }
        }
    }
}
</script>
